import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components-tr/seo"
import { StaticImage } from "gatsby-plugin-image"

const description = "tio anlamlı fikirleri, iyi tasarım ve yeni teknolojilerle bir araya getiren bir dijital ürün stüdyosudur."

const AboutPage = () => (
  <Layout navbarTheme="dark" className="bg-cream">
    <Seo title="tio" description={description} canonical="https://tio.ist/tr/about/" />
 
    <div className=" text-white relative lg:py-20">

      <div className="container text-center mx-auto  py-10 lg:px-10 max-w-4xl">
      <h1 className="text-3xl lg:text-5xl p-10 font-bold text-blue">
        tio anlamlı fikirleri, iyi tasarım ve yeni teknolojilerle bir araya getiren bir <i className="font-serif font-light font-6xl">dijital ürün stüdyosudur.</i> <br/><br/>Dijitalde müşterilerine iyi bir deneyim sunmak isteyen markalara <span className="text-white pr-out">ürünleri</span> ve  <span className="text-white pr-out">hizmetleri</span> ile destek olur.
      </h1>
      </div>
    </div>
    <div className="">
    <div className="container relative py-20 flex flex-wrap">
      <div className="w-full text-left mx-auto py-10 p-10 max-w-xl">
        <h2 className="text-5xl py-10 font-bold text-blue">Bizimle Çalış</h2>
        <p>Üretmeyi, problem çözmeyi, kendini iyi olduğu alanlarda geliştirmeyi isteyen ekip arkadaşları arıyoruz.</p>
        <p className="mt-5">Açık pozisyonlarla ilgileniyorsan bize CV'ni e-posta ile gönderebilirsin.</p>
        <a href="/tr/careers" className="underline py-10 block text-blue">Açık pozisyonlar ↗</a>
        <a href="/tr/careers" className="text-lightBlue block pr-out text-5xl font-bold">Hemen başvur</a>
      </div>
      <div className="w-full text-left mx-auto py-10 p-10 max-w-xl">
        <h2 className="text-5xl py-10 font-bold text-blue">Birlikte Üretelim</h2>
        <p>Dijital ürün üreticileri, tasarımcılar, sanatçılar ve geliştiriciler ile birlikte üretmeyi seviyoruz. </p>
        <p className="mt-5">Birlikte daha iyi bir dünya için bir şeyler üretebileceğimizi düşünüyorsan bize yaz, hemen ne yapabileceğimizi konuşalım.</p>
        <a href="https://medium.com/tio-ist" target="_blank" rel="noreferrer"  className="underline py-10 block text-blue">Hikayemizi oku ↗</a>
        <Link to="/contact" className="text-lightBlue block pr-out text-5xl font-bold">Tanışalım!</Link>
      </div>
    </div>
    </div>
    <div className="w-full">
    <StaticImage className="w-full" src="../images/tio-studio.jpg"   alt="tio" />
    </div>


  </Layout>
)

export default AboutPage
